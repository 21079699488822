import React from 'react'
import Footer from '../components/Footer'
import AUHeroImage from '../components/Hero Images/AUHeroImage'
import Navbar from '../components/Navbar'
import TrainingSection from '../components/Training'

const Training = () => {
    return (
        <div>
            <Navbar />
            <AUHeroImage heading='TRAINING.' text='Pre-Flight & In-Flight Training.' />
            <TrainingSection />
            <Footer />
        </div>
    )
}

export default Training
