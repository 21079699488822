import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import IHeroImage from '../components/Hero Images/IHeroImage'
import InsightsCards from '../components/Insights'

const Insights = () => {
    return (
        <div>
            <Navbar />
            <IHeroImage heading='INSIGHTS' text='Our Thoughts On Innovative Industries.' />
            <InsightsCards />
            <Footer />
        </div>
    )
}

export default Insights