import React from 'react'
import Footer from '../components/Footer'
import ICHeroImage from '../components/Hero Images/ICHeroImage'
import Navbar from '../components/Navbar'
import OurInvestmentCriteriaSummary from '../components/OurInvestmentCriteria1'
import OurInvestmentCriteria2 from '../components/OurInvestmentCriteria2'

const OurInvestmentCriteria = () => {
    return (
        <div>
            <Navbar />
            <ICHeroImage heading='Our Investment Criteria' text='Learn What Makes NuMoney Invest.' />
            <OurInvestmentCriteriaSummary />
            <OurInvestmentCriteria2 />
            <Footer />
        </div>
    )
}

export default OurInvestmentCriteria