import React from 'react'
import './FooterStyles.css'
import { FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaTwitter, FaInstagram, FaTiktok, FaSpotify } from 'react-icons/fa'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='left'>
                    <div className='location'>
                        <FaSearchLocation size={15} style={{ color: '#febe00', marginRight: '2rem' }} />
                        <div>
                            <p2> Passaic,</p2>
                            <h4>New Jersey</h4>
                        </div>
                    </div>
                    <div className='phone'>
                        <h4><FaPhone size={15} style={{ color: '#febe00', marginRight: '2rem' }} /> (201) 455-4852</h4>
                    </div>
                    <div className='email'>
                        <h4><FaMailBulk size={15} style={{ color: '#febe00', marginRight: '2rem' }} /> contact@numoneyholdings.com</h4>
                    </div>
                </div>
                <div className='right'>
                    <h4>About NuMoney</h4>
                    <p2>NuMoney, operating as Business Development Company (BDC), specializes in providing alternative funding solutions to emerging private businesses in niche industries. Our mission is to partner with entrepreneurs that share our passion for innovation and technology. We target investment opportunities that deliver favorable risk-adjusted returns while preserving capital. We strongly believe in a hands-off management approach, only weighing in on major decisions to drive growth through our extensive resources, expertise, capital, partners, and network.</p2>
                    <div className='social'>   
                    <a href="https://www.linkedin.com/company/numoney-holdco" target="_blank"> <FaLinkedin size={30} style={{ color: '#febe00', marginRight: '1rem' }} /></a>
                    <a href="https://www.instagram.com/numoney.holdco/" target="_blank"> <FaInstagram size={30} style={{ color: '#febe00', marginRight: '1rem' }} /></a>
                    <a href="https://open.spotify.com/show/0n4WsBRCdishElmZQGi6iN?si=7a3b046efe6c4399" target="_blank"> <FaSpotify size={30} style={{ color: '#febe00', marginRight: '1rem' }} /></a>
                    <a href="https://www.tiktok.com/@numoney.holdco" target="_blank"> <FaTiktok size={30} style={{ color: '#febe00', marginRight: '1rem' }} /></a>
                    <a href="https://twitter.com/numoney_holdco" target="_blank"> <FaTwitter size={30} style={{ color: '#febe00', marginRight: '1rem' }} /></a>
                    <a href="https://www.facebook.com/numoney.holdco/" target="_blank"> <FaFacebook size={30} style={{ color: '#febe00', marginRight: '1rem' }} /></a>
                    </div>
                    <p1>&#169; 2024 | Innovate with NuMoney.</p1>
                </div>

            </div>
        </div>
    )
}

export default Footer
