import React from 'react'
import {Link} from 'react-router-dom'
import './InsightsStyles.css'

const InsightsCards = () => {
    return (
        <div className='insights'>
            <div className='icard-container'>
                <div className='icard'>
                    <p className='label'>NuMoney: Our Investment Criteria</p>
                    <p1>By NuMoney | 6/18/2022</p1>
                    <span className='barbottom'></span>
                    <p>NuMoney strives to build real and enduring relationships with audacious entrepreneurs. Our cross-portfolio initiatives bring innovation and technology to the forefront of small to medium-sized enterprises in the tri-state area. In bridging the gap between Venture Capital...</p>
                    <Link to='/OurInvestmentCriteria' className='btn'>Continue Reading</Link>
                </div>
                <div className='icard'>
                    <p className='label'>About NuMoney: Welcome</p>
                    <p1>By Bhavik Rana | 5/8/2022</p1>
                    <span className='barbottom'></span>
                    <p>Founded in 2021 by Bhavik Rana, NuMoney bridges the immense gap between Venture Capital and Private Equity by backing curious, innovative, and audacious entrepreneurs of this age. We strive to build real and enduring relationships with people we back while...</p>
                    <Link to='/AboutUs' className='btn'>Continue Reading</Link>
                </div>
            </div>
        </div>
    )
}

export default InsightsCards