import React from 'react'
import {Link} from 'react-router-dom'
import './FocusAreaStyles.css'

const FocusAreaCards = () => {
    return (
        <div className='focusareas'>
            <div className='card-container'>
                <div className='card'>
                    <p className='label'>FinTech</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Full Stack Core Infrastructure & Consumer Facing</p>
                    <span className='barbottom'></span>
                    <p>Banking, Lending, Frontier Tech, Insurance, Real Estate, and more. </p>
                    <p>Hyperfocused on partnering with entrepreneurs pioneering legacy finance infrastructure.</p>
                    <p>Every company is a FinTech company as new forms of financial services outside of the traditional finance landscape are adopted.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Joint Venture/Partnerships, Strategy Consulting, Cost Transformations</p1>
                </div>
                <div className='card'>
                    <p className='label'>Digital Assets</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Exposure To Illiquid & Liquid Blockchain-Related Assets</p>
                    <span className='barbottom'></span>
                    <p>Infrastructure, Defi, DAOs, Web3 Apps, Liquid, and Early Stage Tokens. </p>
                    <p>Concentrated on obtaining exposure to equity in early-stage projects in the form of Venture Equity.</p>
                    <p>This rapidly developing area requires us to be agile and flexible as we work closely with bold entrepreneurs.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Venture Equity, Joint Venture/Partnerships, Strategy Consulting</p1>
                </div>
                <div className='card'>
                    <p className='label'>Technology</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Capital Efficient Manufacturing & Information Exchange Products</p>
                    <span className='barbottom'></span>
                    <p>Cloud, IoT, Artificial Intelligence, IT, Machine Learning, Software, Hardware, and more.</p>
                    <p>The race into the digital future continues as we partner with daring entrepreneurs revolutionizing legacy technology.</p>
                    <p>A dynamic and momentous industry that mandates every enterprise to scale, pivot, and adapt in innovative forms.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Joint Venture, Venture & Growth Equity Solutions, Strategy Consulting</p1>
                </div>
                <div className='card'>
                    <p className='label'>Energy & Nat. Reso.</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Tapping Into The Hypercompetitive ESG-Focused Global Energy Industry</p>
                    <span className='barbottom'></span>
                    <p>Renewables, Chemicals, Oil & Gas, Mining, Net-Zero Projects, and more.</p>
                    <p>Fixated on Green Financing for ESG-driven projects that are striving to build a sustainable future.</p>
                    <p>As climate change effects accelerate, ambitious ESG objectives have a once-in-a-generation opportunity to reshape humanity.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Joint Venture, Buyouts, Growth Equity, Strategy Consulting, Divestitures</p1>
                </div>
                <div className='card'>
                    <p className='label'>Hospitality & Retail</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Omnichannel Focus With Reimagined Customer Experience</p>
                    <span className='barbottom'></span>
                    <p>Restaurants, Bars/Clubs, Hotels, Apparel, Grocery, Mobile Food Services, and more.</p>
                    <p>Innovative omnichannel models with resilient supply chains that are rapidly adjusting to changing consumer expectations.</p>
                    <p>Reimagined business models focused on the digital disruption that reinvents the way we work, play, eat, and socialize.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Digital & Customer Acquisition Strategy, Buyouts, Working Capital Efficiency</p1>
                </div>
                <div className='card'>
                    <p className='label'>Transportation</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Movement of Goods & People: Reimagined</p>
                    <span className='barbottom'></span>
                    <p>Freight Trucking, Taxi Services, Towing, Delivery Drones, and more.</p>
                    <p>Sustainable alternatives to current practices that integrate smart solutions such as IoT, AI, & ML to ultimately reshape the industry.</p>
                    <p>Efficient and resilient transportation methods that are interconnected via modern technology will transform the way we move.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Cost Transformation, Financing Solutions, Strategy Consulting, Partnerships</p1>
                </div>
                <div className='card'>
                    <p className='label'>Service & Contracting</p>
                    <span className='barbottom'></span>
                    <p className='pickup'>Family-Owned Essential Local Enterprises</p>
                    <span className='barbottom'></span>
                    <p>Bodegas, Automotive Repair & Maintenance, Construction, Salons, and more.</p>
                    <p>Essential local/family-owned enterprises that boast a vigorous long-term vision to support local communities.</p>
                    <p>Sticky business models that rely on legacy processes and technology yet manage to become memorable locally.</p>
                    <span className='barbottom'></span>
                    <p1>Our Services: Buyouts, Equity & Debt Solutions, 360 Consulting, Succession Options</p1>
                </div>
            </div>
        </div>
    )
}

export default FocusAreaCards
