import React from 'react'
import './AboutUsStyles.css'

import {Link} from 'react-router-dom'

import NH from '../assets/AU/BR-BW.JPG'
import BR from '../assets/AU/NH.png'

const AboutUs = () => {
    return (
        <div className='au'>
            <div className='left'>
                <h1>About Us</h1>
                <span className='bar'></span>
                <p>NuMoney, founded in 2021 by Bhavik Rana, bridges the gap between Venture Capital and Private Equity by supporting curious, innovative, and audacious entrepreneurs. We aim to build enduring relationships with the individuals we back while bringing innovation and technology to the forefront of small to mid-sized enterprises. Our primary focus is to provide pre-seed to late-stage funding for startups and established businesses in niche markets. Our portfolio companies span from asset management to consuting and target enterprises in niche industries that we have a strong conviction in. We employ tools and proprietary technology that give us a competitive edge.</p> 

                <p>The concept of risk-taking is the driving force behind NuMoney. We understand the challenges faced by entrepreneurs in today's environment, having been there ourselves. We know what it takes to take an idea, execute strategies, experience failures and setbacks, learn from the losses, and ultimately scale. We stand by our clients every step of the way, summarizing these steps into Thinking, Doing, and Growing.</p>
                
                <p>Our long-term vision enables us to execute efficient Buyout strategies and facilitate Growth and Venture Equity strategies through our operating units. Our goal is to bring together like-minded risk-takers, utilizing cross-portfolio initiatives to provide access to our resources, expertise, network, partners, and customers that are pivotal on the path to success.</p>
                <span className='bar'></span>
                <h2>Use the button below to view our current list of operating units.</h2>
                <Link to='/ourportfolio'><button className='btn'>View Our Portfolio</button></Link>
            </div>
            <div className='right'>
                <div className='img-container'>
                    <div className='image-stack top'>
                        <img src={BR} className='img' alt='' />
                    </div>
                    <div className='image-stack bottom'>
                        <img src={NH} className='img' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
