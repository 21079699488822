import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FocusAreaCards from '../components/FocusArea'
import FAHeroImage from '../components/Hero Images/FAHeroImage'

const FocusArea = () => {
    return (
        <div>
            <Navbar />
            <FAHeroImage heading='FOCUS AREAS' text='Innovative Industries. Proven Track Record.' />
            <FocusAreaCards />
            <Footer />
        </div>
    )
}

export default FocusArea
