import {React, useState} from 'react'
import './FormStyles.css'

const Form = () => {
    const[name, setName] = useState ("");
    const[email, setEmail] = useState("");
    const[phone, setPhone] = useState("");
    const[bizname, setBizName] = useState("");
    const[detail, setDetail] = useState("");

    function handleCreateForm(){

    }
    function sendEmail(){

    }
    return (
        <div className='form'>
            <form action="mailto:contact@numoneyholdings.com ?subject=Business Inquiry" method="post" enctype="text/plain">
                <p>To learn more about NuMoney or to schedule an introductory meeting with our founder, please use the button below and send us a short email. If you are considering a partnership with NuMoney or believe we'd be a good match, please reach out and we will be happy to schedule a call to dicuss more.</p>
                {/*<label>Your Name (required)</label>
                <input type='text' onChange={(e) => {
                    setName(e.target.value);
                }}></input>
                <label>Email (required)</label>
                <input type='email'></input>
                <label>Phone Number (required)</label>
                <input type='text'></input>
                <label>Business Name</label>
                <input type='text'></input>
                <label>Details</label>
            <textarea rows='6' placeholder='Type a short message here' />*/}
                                <a href="https://calendly.com/numoneyholdco" target='_blank' className='Link'>Schedule A Free Introductory Call.</a>
                <button className='btn'>Email Us.</button>
            </form>
        </div>
    )
}

export default Form
