import React from 'react'
import {Link} from 'react-router-dom'
import './OurPortfolioStyles.css'

const OurPortfolioCards = () => {
    return (
        <div className='ourportfolio'>
            <div className='card-container'>
                <div className='card'>
                    <h3>Private Equity</h3>
                    <span className='bar'></span>
                    <p className='label'>NuMoney Investments</p>
                    <span className='bar'></span>
                    <p>Specialize in identifying off-market opportunities within niche industries.</p>
                    <p>Originate, execute, and manage equity and debt investments through our internal business lines.</p>
                    <p>Partner with entrepreneurs to acquire, build, and manage enduring businesses.</p>
                    <p1>*If you have a business that you would like us to consider, feel free to contact us.</p1>
                    <Link to='/NuMoneyInvestments' className='btn'>Learn More</Link>
                </div>

                <div className='card'>
                    <h3>Advisory</h3>
                    <span className='bar'></span>
                    <p className='label'>NuMoney Advisors</p>
                    <span className='bar'></span>
                    <p>Holistic consulting approach via partnerships with leaders of emerging businesses.</p>
                    <p>Partner with leaders to identify and evaluate strategic growth opportunities through vertical or horizontal expansions.</p>
                    <p>Comprehensive consulting packages to cultivate enduring relationships.</p>
                    <p1>*If you would like to inquire about our Advisory business, feel free to contact us.</p1>
                    <Link to='/NuMoneyAdvisors' className='btn'>Learn More</Link>
                </div>

                <div className='card'>
                    <h3>Media</h3>
                    <span className='bar'></span>
                    <p className='label'>Nu-llionaires Diary</p>
                    <span className='bar'></span>
                    <p>Enlarge and optimize digital presence with our Podcast & Digital Media company.</p>
                    <p>Sit in with like-minded entrepreneurs to engage in inspiring conversations about their journeys and successes.</p>
                    <p>Dive into the mindsets of hustlers and future leaders with Nu-llionaires Diary.</p>
                    <p1>*If you would like to appear on an episode to talk about your journey, feel free to contact us.</p1>
                    <a href="https://open.spotify.com/show/0n4WsBRCdishElmZQGi6iN?si=7a3b046efe6c4399" target='_blank' className='btn'>Listen On Spotify</a>
                </div>
                
                <div className='card'>
                    <h3>Flagship Fund</h3>
                    <span className='bar'></span>
                    <p className='label'>NuMoney Partners</p>
                    <span className='bar'></span>
                    <p></p>
                    <Link to='/contact' className='btn'>Coming Soon</Link>
                </div>
                <div className='card'>
                    <h3>Alternative Trading System</h3>
                    <span className='bar'></span>
                    <p className='label'>NuMoney Marketplace</p>
                    <span className='bar'></span>
                    <p></p>
                    <Link to='/contact' className='btn'>Coming Soon</Link>
                </div>
            </div>
        </div>
    )
}

export default OurPortfolioCards
