import React from 'react'
import {Link} from 'react-router-dom'
import './NuMoneyAdvisorsCards.css'

const NuMoneyAdvisorsCards = () => {
    return (
        <div className='advisorscards'>
            <div className='acard-container'>
                <div className='acard'>
                    <p className='label'>Strategy Consulting</p>
                    <span className='barbottom'></span>
                    <p>Innovative solutions that help navigate uncertainty in our agile world. Pre-Seed to Late-Stage, we provide holistic perspectives to prepare you for a robust tomorrow.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Finance Consulting</p>
                    <span className='barbottom'></span>
                    <p>82% of businesses fail due to a poor understanding of finance and money management. We want to bring our expertise while ensuring future stability and growth.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Technology Consulting</p>
                    <span className='barbottom'></span>
                    <p>We combine human and technological capabilities to gain a competitive edge and achieve meaningful outcomes. Our deep drive for innovation guides us in accelerating growth, mitigating risks, streamlining business processes, and managing KPIs</p>
                </div>
                <div className='acard'>
                    <p className='label'>Working Capital Efficiency</p>
                    <span className='barbottom'></span>
                    <p>P&L level visibility often overlooks the liquidity component of your business. Our goal is to provide a holistic perspective of cash performance and improve liquidity.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Cost Transformations</p>
                    <span className='barbottom'></span>
                    <p>Long-term visibility into what a business spends can prepare them to respond to the shifting external conditions. Our goal is to provide customizable yet sustainable cost behaviors that allow for organic growth while freeing up cash that can be used to boost other units of the business.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Digital & Customer Acquisition Strategy</p>
                    <span className='barbottom'></span>
                    <p>To thrive in the digital age, transformative digital businesses are essential. Our focus is on creating a robust digital presence with an extensive ecosystem of digital tools that attract and retain long-term customers.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Buyouts</p>
                    <span className='barbottom'></span>
                    <p>Occasionally we come across enterprises that we believe can present tremendous long-term value with a 360 makeover. In these unusual circumstances, we may buy-out the enterprise and run it as we see fit. Other times, owner-operators may want to exit from their businesses and need to sell to us, regardless of the circumstances, we take pride in our investments and run them with a long-term vision.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Divestitures</p>
                    <span className='barbottom'></span>
                    <p>As a company grows, it can end up with assets that are no longer beneficial to them but can be for other businesses. We can help prepare such assets for sale and achieve above-average internal returns on them.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Joint Venture/Partnerships</p>
                    <span className='barbottom'></span>
                    <p>Occasionally we come across enterprises that may benefit from a joint venture or a partnership. We ensure that such businesses can benefit from inorganic growth by taking bold and innovative approaches. Ensuring long-term success and promoting a hands-off management structure we allow them to operate as needed.</p>
                </div>
                <div className='acard'>
                    <p className='label'>Succession Strategy</p>
                    <span className='barbottom'></span>
                    <p>Time waits for no one and lost time is never found again. We are bound to be incapable of making key business decisions as time goes on and it is critical to ensure that the right leaders are in place to adapt accordingly. You can count on us to prepare future key leaders of your business for influential roles.</p>
                </div>
            </div>
        </div>
    )
}

export default NuMoneyAdvisorsCards