import React from 'react'
import './TrainingStyles.css'

import {Link} from 'react-router-dom'

const Training = () => {
    return (
        <div className='training'>
            <div className='left'>
                <h1>Training</h1>
                <p>Thorough training is a necessity when traveling to space. We offer all inclusive training for pre-flight and in-flight scenarios.</p>
                <Link to='/ourportfolio'><button className='btn'>View Our Portfolio</button></Link>
            </div>
            <div className='right'>
                <div className='img-container'>
                </div>
            </div>
        </div>
    )
}

export default Training
