import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import OurPortfolioCards from '../components/OurPortfolio'
import OPHeroImage from '../components/Hero Images/OPHeroImage'

const OurPortfolio = () => {
    return (
        <div>
            <Navbar />
            <OPHeroImage heading='OUR PORTFOLIO' text='Multiple Industries. One Vision.' />
            <OurPortfolioCards />
            <Footer />
        </div>
    )
}

export default OurPortfolio
