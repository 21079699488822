import React from 'react'
import './NuMoneyAdvisorsStyles.css'

import {Link} from 'react-router-dom'

import NABack from '../assets/NA/NA-Back.jpg'
import NAImage from '../assets/NA/NA.png'

const NuMoneyAdvisors = () => {
    return (
        <div className='na'>
            <div className='left'>
                <h1>NuMoney Advisors</h1>
                <p>A New Jersey-based consulting firm that brings innovation and technology at the forefront of leaders and entrepreneurs of this age that want to define the future. Operating as a business unit of NuMoney, we strive to tackle the most important challenges while identifying and capturing opportunities. Our holistic approach allows us to tap into the suppressed and hidden potential of enterprises by challenging the traditional ways of thinking and operating.</p>
                <p>We work relentlessly with business leaders and entrepreneurs across industries to redefine traditional thought processes and solve industry-defining challenges. We shine in integrating cross-portfolio to deliver innovative, enduring, and sustainable results for our clients.</p>

                <span className='bar'></span>
                <p>Our advisory business provides the following services:</p>
                <p>
                    <dl>
                    <li>Strategy Consulting</li>
                    <li>Finance Consulting</li>
                    <li>Technology Consulting</li>        
                    <li>Working Capital Efficiency</li>
                    <li>Cost Transformation Strategy</li>
                    <li>Digital & Customer Acquisition Strategy</li>
                    <li>Buyouts</li>
                    <li>Divestitures</li>
                    <li>Joint Ventures/Partnerships</li>
                    <li>Succession Strategy</li>
                    <li>360 degree Consulting</li>       
                    </dl>
                </p>
                <span className='bar'></span>

                <p>We partner with bold and ambitious entrepreneurs to achieve exceptional outcomes that can shape our tomorrow. Together, we create enduring results and share NuMoney’s broader belief: The Audacious Achieve the Extraordinary.</p>
                
                <h2>If you are a business owner and would like to discuss our Advisory services, feel free to contact us using the button below or give us a call.</h2>
                <Link to='/contact'><button className='btn'>Contact</button></Link>
            </div>
            <div className='right'>
                <div className='img-container'>
                    <div className='image-stack top'>
                        <img src={NAImage} className='img' alt='' />
                    </div>
                    <div className='image-stack bottom'>
                        <img src={NABack} className='img' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NuMoneyAdvisors
