import React from 'react'
import './OurInvestmentCriteria.css'

import {Link} from 'react-router-dom'

const OurInvestmentCriteria1 = () => {
    return (
            <div className='ic ic1'>
            <div className='left'>
                <h1>Summary</h1>
                <span className='bar'></span>
                <p>NuMoney is committed to building long-lasting relationships with innovative and audacious entrepreneurs. Through our cross-portfolio initiatives, we bring cutting-edge technology and innovation to the forefront of the businesses we invest in.</p>

                <p>Our unique position as a bridge between Venture Capital and Private Equity allows us to operate with a long-term vision, facilitating various investment strategies through a permanent capital source. We are open to leading investment rounds or co-investing with other groups, partnering with management teams to grow businesses, or conducting traditional buy-outs.</p>

                <p>Additionally, our GP Stakes business line is comfortable making early-stage or venture investments in innovative pre-revenue companies. We also offer follow-on investments to our portfolio companies when our conviction is robust.</p>
                
                <h2>Use the button below to learn more about us.</h2>
                <Link to='/aboutus'><button className='btn'>Learn More About NuMoney</button></Link>
                <span className='bar'></span>
            </div>            
        </div>
    )
}

export default OurInvestmentCriteria1