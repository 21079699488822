import React from 'react'
import Footer from '../components/Footer'
import AUHeroImage from '../components/Hero Images/AUHeroImage'
import Navbar from '../components/Navbar'
import AboutUsSection from '../components/AboutUs'

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <AUHeroImage heading='NUMONEY HOLDINGS' text='Embark On A New Venture.' />
            <AboutUsSection />
            <Footer />
        </div>
    )
}

export default AboutUs