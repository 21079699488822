import React from 'react'
import Footer from '../components/Footer'
import NIHeroImage from '../components/Hero Images/NIHeroImage'
import Navbar from '../components/Navbar'
import NuMoneyInvestmentsSection from '../components/NuMoneyInvestments'

const NuMoneyInvestments = () => {
    return (
        <div>
            <Navbar />
            <NIHeroImage heading='NUMONEY INVESTMENTS' text='Alternative Funding Solutions.' />
            <NuMoneyInvestmentsSection />
            <Footer />
        </div>
    )
}

export default NuMoneyInvestments