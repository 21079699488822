import React from 'react'
import { Link } from 'react-router-dom'
import Typed from 'react-typed'
import './VideoStyles.css'
import ReactPlayer from 'react-player'

import nycVideo from '../assets/video-6.mp4'

const Video = () => {
    return (
        <div className='hero'>
            <video playsInline autoPlay loop muted id='video'>
                <source src={nycVideo} type='video/mp4' />
            </video>
            <div className='content'>
                <h1> 
                    Establishing Enduring
                    
                    <Typed
                    strings={[
                        ": Partnerships.",
                        ": Businesses.",
                        ": Futures.",
                    ]}
                    typeSpeed={150}
                    backSpeed={100}
                    loop
                /> </h1>
                <p>Combining trust, technology, & experience.</p>
                <div>
                    <Link to='/aboutus' className='btn'>Learn More</Link>
                    <Link to='/ourportfolio' className='btn'>Our Portfolio</Link>
                </div>
            </div>
        </div>
    )
}

export default Video