import React from 'react'
import Footer from '../components/Footer'
import CUHeroImage from '../components/Hero Images/CUHeroImage'
import Navbar from '../components/Navbar'
import Form from '../components/Form'

const Contact = () => {
    return (
        <div>
            <Navbar />
            <CUHeroImage heading='CONTACT' text='NuMoney Holdings' />
            <Form />
            <Footer/>
        </div>
    )
}

export default Contact
