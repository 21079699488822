import React from 'react'
import './NuMoneyInvestmentsStyles.css'

import {Link} from 'react-router-dom'

import NIBack from '../assets/NI/NI-Back-2.jpg'
import NIImage from '../assets/NI/NI.png'

const NuMoneyInvestments = () => {
    return (
        <div className='ni'>
            <div className='left'>
                <h1>NuMoney Investments</h1>
                <p>A New Jersey-based Private Equity platform focused on investing and partnering with industry-leading private businesses. Operating as a business unit of NuMoney, we strategically place funds into businesses poised for significant improvement or growth through our internal GP Stakes and Debt Solutions business lines. Our mission is to partner with management teams and entrepreneurs to leverage our industry knowledge, investment experience, and operational expertise to generate value.</p>

                <span className='bar'></span>
                <p>NuMoney Investment's business lines can be simplified as following:</p>
                <p>
                    <dl>
                    <li>Our GP Stakes business line follows the traditional buyout and build-up strategy with a technologically driven core. We also actively seek opportunities to provide Growth Equity or Venture Equity investments to audacious entrepreneurs in niche markets.</li>
                    <li>Our Debt Solutions business line provides financing solutions to established companies in a strong competitive position. We focus on first lien/senior secured, unitranche, mezzanine, preferred equity, and convertible debt loans with a term of 1 to 3 years. Our primary focus is the provide sizable commitments and facilitate transactions to support the growth-related needs of borrowers. We are also comfortable leading, co-managing, or syndicating financing solutions. 
                    </li>                    
                    </dl>
                </p>
                <span className='bar'></span>
                <p>We stride ourselves in partnering closely with fearless business leaders to build and manage enduring businesses in an agile environment.</p>
                <h2>If you have a business that you would like us to consider for an investment or a partnership, feel free to contact us using the button below or give us a call.</h2>
                <Link to='/contact'><button className='btn'>Contact</button></Link>
            </div>
            <div className='right'>
                <div className='img-container'>
                    <div className='image-stack top'>
                        <img src={NIImage} className='img' alt='' />
                    </div>
                    <div className='image-stack bottom'>
                        <img src={NIBack} className='img' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NuMoneyInvestments
