import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Contact from './routes/Contact';
import FocusArea from './routes/FocusArea';

import Home from './routes/Home';
import NuMoneyAdvisors from './routes/NuMoneyAdvisors';
import NuMoneyInvestments from './routes/NuMoneyInvestments';
import OurPortfolio from './routes/OurPortfolio';
import AboutUs from './routes/AboutUs';
import Training from './routes/Training';
import Insights from './routes/Insights';
import ScrollToTop from './components/ScrollToTop';
import OurInvestmentCriteria from './routes/OurInvestmentCriteria';

function App() {
  return (
    <>
    <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/OurPortfolio' element={<OurPortfolio />} />
        <Route path='/FocusArea' element={<FocusArea />} />
        <Route path='Insights' element={<Insights />} />
        <Route path='/training' element={<Training />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/NuMoneyInvestments' element={<NuMoneyInvestments />} />
        <Route path='/NuMoneyAdvisors' element={<NuMoneyAdvisors />} />
        <Route path='/OurInvestmentCriteria' element={<OurInvestmentCriteria />} />
      </Routes>
    </>
  );
}

export default App;
