import React from 'react'
import './OurInvestmentCriteria.css'

import {Link} from 'react-router-dom'

const OurInvestmentCriteria1 = () => {
    return (
            <div className='ic ic2'>
            <div className='left'>
                <h1>Our Criteria</h1>
                <span className='bar'></span>
                <p><u>Financial Benchmarks</u></p>
                <p>NuMoney aims to target companies that generally fit the following criteria:</p>
                <p>
                    <li>Revenues of <u>$100,000+</u></li>
                    <li>EBITDA Margins in <u>excess of 40%</u></li>
                </p>
                <p><u>Preferred Companies</u></p>
                <p>
                    <li>Private, entrepreneur or family-owned</li>
                    <li>Companies that are in need of capital</li>
                    <li>Retirement-ready business owners</li>
                    <li>Excellent management with happy employees, if any</li>
                </p>
                <p><u>Types of Transactions</u></p>
                <p>
                    <li>Leveraged buyouts</li>
                    <li>Equity transactions</li>
                    <li>Buy-and-build</li>
                    <li>Owner recapitalization</li>
                </p>
                <p><u>Transaction Structure</u></p>
                <p>
                    <li>Controlling or non-controlling interest</li>
                    <li>Common, preferred, or mezzanine</li>
                    <li>Independent sponsors or co-investment</li>
                </p>
                <span className='bar'></span>
            </div>
            <div className='left'>
                <h1>Areas of Interest</h1>
                <span className='bar'></span>
                <p>NuMoney considers investments across a broad range of industries, more specifically the below industries excite us given our broad scope of industry knowledge</p>
                <p>
                    <li>Service & Contracting</li>
                    <li>Hospitality & Retail</li>
                    <li>Transportation</li>
                    <li>FinTech</li>
                    <li>Digital Assets</li>
                    <li>Technology</li>
                    <li>Energy & Natural Resources</li>
                </p>
                <p>In addition to the above industries, we aim to tackle the most challenging problems thrown in our direction and are happy to evaluate an opportunity that is outside our criteria for investment.</p>
                <p>If you are a business owner or an entrepreneur that believes you meet the criteria we have outlined or believe we'd be a good match to tackle a joint venture, we are interested in learning more about what you have built and have planned.</p>
                <p>The more specific your niche and the bolder the idea, the more enthusiastic our team here at NuMoney is.</p>
                <Link to='/focusarea'><button className='btn'>View Our Focus Areas</button></Link>
                <span className='bar'></span>
            </div>          
        </div>
    )
}

export default OurInvestmentCriteria1