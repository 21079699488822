import React from 'react'
import Footer from '../components/Footer'
import NAHeroImage from '../components/Hero Images/NAHeroImage'
import Navbar from '../components/Navbar'
import NuMoneyAdvisorsSection from '../components/NuMoneyAdvisors'
import NuMoneyAdvisorsCards from '../components/NuMoneyAdvisorsCards'

const NuMoneyAdvisors = () => {
    return (
        <div>
            <Navbar />
            <NAHeroImage heading='NUMONEY ADVISORS' text='Holistic Approach In An Agile Landscape.' />
            <NuMoneyAdvisorsSection />
            <NuMoneyAdvisorsCards />
            <Footer />
        </div>
    )
}

export default NuMoneyAdvisors